<template>
  <section class="section">
    <div class="container">
      <div v-if="!loading">
        <div v-if="requisitions.length > 0">
          <el-switch class="has-small-margin-right" v-model="viewingTestData"></el-switch>
          <span v-if="viewingTestData" class="is-size-6-7">Viewing Test Data</span>
          <span v-else class="is-size-6-7">View Test Data</span>
          <el-table :data="filteredTableData" :default-sort="{ prop: 'date', order: 'descending' }">
            <el-table-column type="expand">
              <template slot-scope="scope">
                <p>Username: {{ scope.row.owner }}</p>
                <p class="is-capitalized" v-if="scope.row.paymentType">
                  Payment Type:
                  <span class="is-capitalized">{{ scope.row.paymentType.toLowerCase() }}</span>
                </p>
                <div v-if="scope.row.output">
                  <div v-if="scope.row.output[0].type === OUTPUT_TYPE.VIDEO">
                    <video
                      :style="{ width: '250px' }"
                      playsinline="true"
                      autoplay="true"
                      loop="true"
                      muted="muted"
                      controls
                      :src="scope.row.output[0].value"
                    ></video>
                  </div>
                  <div v-else-if="scope.row.output[0].type === OUTPUT_TYPE.IMAGE">
                    <el-image :src="scope.row.output[0].value" fit="contain" style="height: 250px"></el-image>
                  </div>
                  <div v-else-if="scope.row.output[0].type === OUTPUT_TYPE.IMAGE">
                    <audio :src="scope.row.output[0].value" controls></audio>
                  </div>
                  <div v-else>
                    {{ scope.row.output[0].label || scope.row.output[0].key }} :{{ scope.row.output[0].value }}
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Service">
              <template slot-scope="scope">
                <span class="has-small-margin-right">{{ scope.row.service }}</span>
                <el-tag size="mini" v-show="scope.row.isTest">Test</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="date" label="Order Date" :formatter="formatDate" sortable></el-table-column>
            <el-table-column prop="amount" label="Amount" :formatter="formatAmount" sortable></el-table-column>
            <el-table-column prop="status" label="Status" :formatter="formatStatus"></el-table-column>
            <el-table-column prop="outputNote" label="Note"></el-table-column>
            <el-table-column width="180">
              <template slot-scope="scope">
                <div class="columns is-mobile">
                  <div class="column">
                    <el-button type="text" :loading="scope.row.retrying" @click="retry(scope.row.id, scope.row)"
                      >Retry</el-button
                    >
                  </div>
                  <div class="column">
                    <router-link :to="`/order/${scope.row.id}`">
                      <el-button type="text">View Order</el-button>
                    </router-link>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-button
            class="has-margin-top"
            v-if="!noMoreRequisitionsToLoad"
            @click="loadMoreRequisitions"
            :loading="loadingReqs"
            >Load More</el-button
          >
        </div>
        <div v-else>Looks like you don't have any requisitions yet</div>
      </div>
      <div v-else>Loading...</div>
    </div>
  </section>
</template>

<script>
import { API, graphqlOperation, Auth } from 'aws-amplify'
import axios from 'axios'
import { STATUS, OUTPUT_TYPE } from '@/graph-constants'
import { vendorRetryEndpoint } from '@/api/requisitions'
import { timeFormat } from 'd3'

const reqsByVendor = `query ReqsByVendor(
  $vendor: String
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelRequisitionFilterInput
  $limit: Int
  $nextToken: String
) {
  reqsByVendor(
    vendor: $vendor
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      service {
        id
        title
        amount
        owner
      }
      amount
      createdAt
      status {
        value
        date
        description
      }
      output {
        key
        value
        label
        type
      }
      outputNote
      canceled
      refunded
      archived
      test
      owner
      vendor
      api
      paymentType
      subscription {
        id
        stripeId
        owner
        createdAt
      }
    }
    nextToken
  }
}
`

const requisitionDateTimeFormat = timeFormat('%b %d at %-I:%M %p')

const requisitionTableMap = (requisition) => ({
  service: requisition.service.title,
  date: new Date(requisition.createdAt),
  amount: requisition.amount,
  status: requisition.status[requisition.status.length - 1].value,
  outputNote: requisition.outputNote,
  id: requisition.id,
  retrying: false,
  isTest: requisition.vendor === requisition.owner,
  owner: requisition.owner,
  output: requisition.output,
  paymentType: requisition.paymentType,
})

export default {
  name: 'VendorRequisitions',
  data() {
    return {
      OUTPUT_TYPE,
      requisitions: [],
      viewingTestData: false,
      nextToken: null,
      loading: true,
      noMoreRequisitionsToLoad: false,
      loadingReqs: false,
    }
  },
  async created() {
    this.requisitions = await this.fetchRequisitions(10)
    this.loading = false
  },
  computed: {
    filteredTableData() {
      return this.requisitions.map(requisitionTableMap).filter((d) => d.isTest === this.viewingTestData)
    },
  },
  methods: {
    async fetchRequisitions(limit) {
      this.loadingReqs = true
      const res = await API.graphql(
        graphqlOperation(reqsByVendor, {
          nextToken: this.nextToken,
          vendor: this.$store.state.user.username,
          sortDirection: 'DESC',
          limit,
        })
      )
      if (res.data.reqsByVendor.items.length === 0) {
        this.noMoreRequisitionsToLoad = true
      }
      this.nextToken = res.data.reqsByVendor.nextToken
      this.loadingReqs = false
      return res.data.reqsByVendor.items
    },
    async loadMoreRequisitions() {
      const moreReqs = await this.fetchRequisitions(10)
      this.requisitions = this.requisitions.concat(moreReqs)
    },
    formatDate(row, col, cellValue) {
      return requisitionDateTimeFormat(cellValue)
    },
    formatAmount(row, col, cellValue) {
      return `$${(cellValue / 100).toFixed(2)}`
    },
    formatStatus(row, col, cellValue) {
      const map = {
        [STATUS.CREATED]: 'Order initiated',
        [STATUS.RECEIVED]: 'Order received',
        [STATUS.PROCESSING]: 'Order processing',
        [STATUS.REJECTED]: 'Rejected',
        [STATUS.NOT_RECEIVED]: 'Not Accepted',
        [STATUS.FULFILLED]: 'Fulfilled',
      }
      return map[cellValue] || ''
    },
    async retry(id, row) {
      row.retrying = true
      try {
        const session = await Auth.currentSession()
        const headers = {
          Authorization: session.idToken.jwtToken,
        }
        await axios.post(vendorRetryEndpoint, { id }, { headers })
        this.$message({
          message: 'Retrying order request',
        })
        row.retrying = false
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
